@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$black: #000;
$white: #fff;

*,
html {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.app-header {
  position: fixed;
  height: 10vh;
  width: 100%;
  z-index: 100;
  background-color: $black;
  color: $white;
}

.app-header.scrollDown {
  position: fixed;
  height: 5vh;
  width: 100%;
  z-index: 100;
  background-color: $black;
  color: $white;
  transition: height 0.5s ease-in-out;
}

.app-header.scrollUp {
  position: fixed;
  min-height: 10vh;
  width: 100%;
  z-index: 100;
  background-color: $black;
  color: $white;
  transition: height 0.5s ease-in-out;
}

.app-main {
  position: relative;
  top: 10vh;
  width: 100%;
  min-height: 90vh;
  height: auto;

  .app-footer {
    position: relative;
    bottom: 0;
    height: 10vh;
    width: 100%;
    background-color: $black;
    color: $white;
  }
}

@media screen and (max-width: 1600px) {
  .app-header.scrollDown {
    position: fixed;
    height: 7vh;
    width: 100%;
    z-index: 100;
    background-color: $black;
    color: $white;
    transition: height 0.5s ease-in-out;
  }
}

@media screen and (max-width: 1000px) {
  .app-header.scrollDown {
    position: fixed;
    height: 5vh;
    width: 100%;
    z-index: 100;
    background-color: $black;
    color: $white;
    transition: height 0.5s ease-in-out;
  }
}
