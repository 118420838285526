.registerSection {
  width: 100%;
  background-color: white;
  color: black;

  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form span {
  font-size: 16px;
  font-weight: 400;
  color: #ff0000;
}

.registerSection__title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 25px;
}

.registerSection__text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;
}

.registerSection__input {
  width: 40%;
}

.registerSection__inputBoxFirst,
.registerSection__inputBoxLast,
.registerSection__inputBoxCompany,
.registerSection__inputBoxEmail,
.registerSection__inputBoxPassword,
.registerSelection__country {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.registerSection__input input {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 26px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 25px;
}

.registerSelection__country select {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 26px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 100px;
}

.registerSection__reset {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 75px;
  cursor: pointer;
}

.registerSection__button {
  display: flex;
  width: calc(100% - 75%);
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 150px auto;
}

.registerSectionButton__submit {
  font-size: 16px;
  font-weight: 300;
  background-color: black;
  color: white;
  width: 153px;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
}

.registerSectionButton__submit:hover {
  cursor: pointer;
}
.registerSectionButton__reset {
  font-size: 16px;
  font-weight: 300;
  background-color: #cbcbcb;
  color: black;
  width: 126px;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
}

.registerSectionButton__reset:hover {
  cursor: pointer;
}
