.contactSection {
  width: 100%;
  background-color: white;
  color: black;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}

// header Section

.contactSection__header {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactSection__header img {
  position: relative;
  height: auto;
  width: 100%;
}

img.contactSectionHeader__background {
  height: 400px;
  object-fit: cover;
}

.contactSectionHeader__title {
  position: absolute;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}

@media (max-width: 600px) {
  .contactSectionHeader__title {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .contactSectionHeader__title {
    font-size: 28px;
  }
}

// content Section

.contactSection__content {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1250px) {
  .contactSection__content {
    width: calc(100% - 9%);
  }
}

@media (max-width: 1050px) {
  .contactSection__content {
    flex-direction: column;
    width: calc(100% - 9%);
  }
}

@media (max-width: 600px) {
  .contactSection__content {
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .contactSection__content {
    margin-bottom: 25px;
  }
}

@media (max-width: 400px) {
  .contactSection__content {
    margin-bottom: 0;
  }
}

// Information Section

.contactSectionContent__information {
  display: flex;
  align-items: center;
  margin-right: 150px;
}

.contactSectionContentInformation__company {
  height: fit-content;
  padding-right: 150px;
}

.contactSectionContentInformationCompany__card {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.contactSectionContentInformationCompanyCard__image {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.contactSectionContentInformationCompany__card:first-child img{
  margin: 0 25px;
  width: 38px;
}

.contactSectionContentInformationCompany__card:last-child img{
  margin: 0 25px;
  width: 38px;
}

.contactSectionContentInformationCompanyCardText__title h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

.contactSectionContentInformationCompanyCard__text {
  margin-bottom: 20px;
}

.contactSectionContentInformationCompanyCardText__subTitle p {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 1250px) {
  .contactSectionContent__information {
    margin-right: 0;
  }
}

@media (max-width: 1050px) {
  .contactSectionContentInformation__company {
    padding-right: 0;
  }
}

// Form Scetion

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form span {
  font-size: 16px;
  font-weight: 400;
  color: #ff0000;
}

.contactSectionContentInformation__input input,
.contactSectionContentInformationInput__BoxHelp textarea {
  font-size: 14px;
  font-weight: 400;
  width: 510px;
  padding: 5px 5px;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 25px;
}

.contactSectionContentInformation__input div:first-child {
  margin-top: 5px;
}

.contactSectionContentInformation__input div:last-child {
  margin-bottom: 60px;
}

.contactSectionContentInformationInput__BoxFirst,
.contactSectionContentInformationInput__BoxLast,
.contactSectionContentInformationInput__BoxCompany,
.contactSectionContentInformationInput__BoxEmail,
.contactSectionContentInformationInput__country,
.contactSectionContentInformationInput__BoxHelp textarea {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.contactSectionContentInformationInput__country select {
  font-size: 14px;
  font-weight: 400;
  width: 510px;
  padding: 5px 26px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin-bottom: 25px;
}

.contactSectionContentInformation__valid {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.contactSectionContentInformation__button {
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
}

.contactSectionContentInformationButton__submit {
  font-size: 16px;
  font-weight: 400;
  background-color: black;
  border: none;
  color: white;
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 15px;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.contactSectionContentInformationButton__reset {
  font-size: 16px;
  font-weight: 400;
  background-color: #cccccc;
  border: none;
  color: black;
  width: fit-content;
  height: fit-content;
  text-align: center;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 600px) {
  form {
    width: calc(100% - 6%);
  }
  .contactSectionContentInformation__input input,
  .contactSectionContentInformationInput__BoxHelp textarea {
    width: 100%;
  }
  .contactSectionContentInformation__input div:last-child {
    margin-bottom: 25px;
  }
  .contactSectionContentInformation__valid {
    align-items: center;
    flex-direction: column;
  }
  .contactSectionContentInformation__button {
    justify-content: center;
    margin: 25px 0;
  }
}

// remoteMeetings Section

.contactSectionRemoteMeetings__image img {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 85px;
}

.contactSectionRemoteMeetingsImage__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.contactSectionRemoteMeetingsImageWrapper__title {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -395px;
  left: 7%;
  transform: translate(0%, -50%);
}

.contactSectionRemoteMeetingsImageWrapper__title h3 {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  color: black;
  line-height: 1;
}

.contactSectionRemoteMeetingsImageWrapper__text {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 650px;
  top: -270px;
  left: 7%;
  transform: translate(0%, -50%);
}

.contactSectionRemoteMeetingsImageWrapper__text p {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: black;
  line-height: 23px;
}

.contactSectionRemoteMeetingsImageWrapperText__subText {
  margin-left: 50px;
  margin-top: 10px;
}

.contactSectionRemoteMeetingsImageWrapperText__subText span {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: black;
  line-height: 1;
}

// banner Section

.contactSection__reactivSuite {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.contactSectionReactivSuite__Background {
  display: flex;
  margin-top: 5px;
  background-color: black;
  width: 1120px;
  height: 284px;
  box-shadow: 0 0 15px 2px black;
  margin-bottom: 50px;
}

.contactSectionReactivSuite__Background:before {
  content: "";
  z-index: 1;
  inset: 0;
  border-radius: inherit;
  padding: 3px;
  background: #f37643;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.contactSectionReactivSuiteImageWrapper__title h3 {
  font-size: 35px;
  font-weight: 700;
  color: white;
  line-height: 1;
}

.contactSectionReactivSuiteImageWrapper__text p {
  font-size: 17px;
  font-weight: 400;
  margin-top: 35px;
  margin-bottom: 30px;
  color: white;
  line-height: 23px;
}

.contactSectionReactivSuiteImageWrapper__button {
  font-size: 16px;
  font-weight: 400;
  background-color: #ffffff;
  color: black;
  width: fit-content;
  height: 52px;
  text-align: center;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.contactSectionReactivSuiteImageWrapper__left {
  display: flex;
  width: 100%;
  margin: auto 30px;
  flex-direction: column;
  justify-content: space-between;
}

.contactSectionReactivSuiteImageWrapper__right {
  display: flex;
  width: 900px;
  justify-content: center;
  align-items: center;
}
