.headerSection {
  width: 100%;
  height: 10vh;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerSection.scrollDown {
  width: 100%;
  height: 5vh;
  transition: height 0.5s ease-in-out;
}

.headerSection.scrollUp {
  width: 100%;
  height: 10vh;
}

//Dropdowns
.headerSection__dropdown-label {
  padding: 16px;
}

.headerSection__dropdown-options {
  list-style: none;
  position: absolute;
  background-color: #f37643;
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
}

.headerSection__dropdown-label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.headerSection__dropdown-options:hover {
  cursor: pointer;
}

.headerSection__dropdown-options > li:hover {
  text-decoration: underline;
}

.headerSection__dropdown-options > li {
  padding: 8px;
}

//Left Part of the header
.headerSection__left {
  width: fit-content;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  & > a {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.headerSectionLeft__logo {
  width: auto;
  height: 50%;
  margin: 0 16px 0 16px;
}

//Right Part of the header
.headerSection__right {
  width: fit-content;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

//Languages
//.headerSectionRight__languages {
//  display: flex;
//  align-items: center;
//  height: 100%;
//  margin-right: 20px;
//  font-size: 20px;
//}
//
//.headerSectionRightLanguages__item {
//  cursor: pointer;
//}
//
//.headerSectionRightLanguages__separator {
//  margin: 0px 4px 0px 4px;
//}
//
//.selected {
//  font-weight: bold;
//}

//Links

.headerSectionRight__link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.headerSectionRight__button {
  width: 160px;
  padding: 12px;
  background-color: white;
  color: black;
  border-radius: 12px;
  margin-right: 16px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.headerSectionRight__icon {
  width: auto;
  height: 60%;
  margin-right: 16px;
}

a .footerSectionMainPartText__link:hover {
  cursor: pointer;
}

.footerSectionMainPartText__link {
  text-decoration: none;
  color: white;
}

@media (max-width: 1600px) {
  .headerSection.scrollDown {
    height: 7vh;
  }
}

@media (max-width: 1000px) {
  .headerSection.scrollDown {
    height: 5vh;
  }
}

@media (max-width: 450px) {
  .headerSectionLeft__logo {
    width: 100px;
    height: auto;
  }
  .headerSectionRight__button {
    font-size: 14px;
    padding: 7px;
    border-radius: 10px;
    width: 130px;
  }
}
