$blue: #236eff;
$red: #db1a00;
$orange: #eb8d00;

.solutionsSection {
  min-height: 60vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.solutionsSection__main {
  width: 75%;
}

/*Intro*/

.solutionsSectionMain__intro {
  margin-top: 64px;
  margin-bottom: 128px;
}

.solutionsSectionMainIntro__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.solutionsSectionMainIntro__paragraph {
  font-weight: 500;
}

/*Card 1 - image on the right*/
.solutionsSectionMain__card {
  width: 100%;
  height: 450px;
  margin-bottom: 128px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solutionsSectionMainCard__leftpart {
  width: 55%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.solutionsSectionMainCardLeftpart__title {
  font-size: 48px;
  font-weight: 700;
}

.solutionsSectionMainCardLeftpart__title::after {
  content: " FOR";
  color: $blue;
}

.solutionsSectionMainCardLeftpart__title2 {
  font-size: 48px;
  font-weight: 700;
}

.solutionsSectionMainCardLeftpart__title2::after {
  content: " COM";
  color: $orange;
}

.solutionsSectionMainCardLeftpart__undertitle {
  font-size: 32px;
  font-weight: 700;
}

.solutionsSectionMainCardLeftpart__paragraph {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  text-align: justify;
  width: 90%;
}

.solutionsSectionMainCardLeftpart__btn {
  background-color: $blue;
  color: #fff;
  padding: 16px;
  font-weight: 700;
  border-radius: 28px;
  text-decoration: none;
}

.solutionsSectionMainCardLeftpart__btn2 {
  background-color: $orange;
  color: #fff;
  padding: 16px;
  font-weight: 700;
  border-radius: 28px;
  text-decoration: none;
}

.solutionsSectionMainCard__rightpart {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.solutionsSectionMainCardRightpart__img {
  height: 75%;
  aspect-ratio: 1/1;
}

/*Card 2 - image on the left*/
.solutionsSectionMain__card2 {
  width: 100%;
  height: 450px;
  margin-bottom: 128px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.solutionsSectionMainCard2__leftpart {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.solutionsSectionMainCard2Leftpart__img {
  height: 75%;
  aspect-ratio: 1/1;
}

.solutionsSectionMainCard2__rightpart {
  width: 55%;
  height: 100%;
  text-align: end;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.solutionsSectionMainCard2Rightpart__title {
  font-size: 48px;
  font-weight: 700;
}

.solutionsSectionMainCard2Rightpart__title::after {
  content: " OPS";
  color: $red;
}

.solutionsSectionMainCard2Rightpart__undertitle {
  font-size: 32px;
  font-weight: 700;
}

.solutionsSectionMainCard2Rightpart__paragraph {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  text-align: justify;
  width: 90%;
}

.solutionsSectionMainCard2Rightpart__btn {
  background-color: $red;
  color: #fff;
  padding: 16px;
  font-weight: 700;
  border-radius: 28px;
  text-decoration: none;
}

/*Commun*/
.learnMoreButton:hover {
  cursor: pointer;
}
