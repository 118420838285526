.loginSection {
  width: 100%;
  background-color: white;
  color: black;

  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form span {
  font-size: 16px;
  font-weight: 400;
  color: #ff0000;
}

.loginSection__title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 25px;
}

.loginSection__text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;
}

.loginSection__input {
  width: 40%;
}

.loginSection__inputBoxEmail,
.loginSection__inputBoxPassword {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.loginSection__inputBoxEmail {
    margin-top: 50px;
    margin-bottom: 25px;
}

.loginSection__input input {
  font-size: 24px;
  font-weight: 400;
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 2px;
}

.loginSection__reset {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 75px;
  cursor: pointer;
}

.loginSection__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.loginSectionButton__submit {
  font-size: 16px;
  font-weight: 300;
  background-color: black;
  color: white;
  width: 153px;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
}

.loginSectionButton__submit:hover {
  cursor: pointer;
}
