.footerSection {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*Main Part*/

.footerSection__main {
  width: 100%;
  height: 50%;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr) 30%;
  column-gap: 16px;
}

.footerSectionMain_part1 {
  position: relative;
  height: 105%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-left: 16px;
}

.footerSectionMain_part {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 32px;
}

.footerSectionMain_partInstall {
  position: absolute;
  right: 0px;
  bottom: 25%;
  width: 330px;
  padding-bottom: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerSectionMainPart__title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerSectionMainPart__text {
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 150%;
  font-size: 16px;
}

.footerSectionMainPart__logo {
  width: 128px;
}

.footerSectionMainPart__socials {
  width: 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerSectionMainPart__social {
  border: 1px solid white;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.footerSectionMainPart__dowloads {
  margin-top: 16px;
  height: 100%;
  width: 330px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerSectionMainPart__download {
  border: 1px solid white;
  width: 35%;
  max-width: 150px;
  height: 42px;
  border-radius: 4px;
  margin-right: 16px;
}

.footerSectionMainPart__title:hover,
.footerSectionMainPart__text:hover {
  cursor: pointer;
}

@media (max-width: 450px) {
  .footerSectionMainPart__logo {
    width: 100px;
  }
}

/*Bottom Part*/

.footerSection__bottom {
  position: absolute;
  width: calc(100% - 16px);
  padding-right: 16px;
  padding: auto 0;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.5px;
  font-weight: 300;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 450px) {
  .footerSection__bottom {
    font-size: 10px;
  }
}
