//Languages
.headerSectionRight__languages {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  font-size: 20px;
}

.headerSectionRightLanguages__item {
  cursor: pointer;
}

.headerSectionRightLanguages__separator {
  margin: 0 4px 0 4px;
}

.selected {
  font-weight: bold;
}