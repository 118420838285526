$gray : #eaeaea;

.resourcesSection{
    min-height: 60vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


/*Intro*/

.resourcesSection__intro{
    width: 75%;
    margin-top: 64px;
    margin-bottom: 128px;
}

.resourcesSectionIntro__title{
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 32px;
}

.resourcesSectionIntro__paragraph{
    font-weight: 500;
}

/*Card*/

.resourcesSection__card{
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 64px;
    width: calc(100% - 16px);
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.resourcesSectionCard__title{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 32px;
}

.resourcesSectionCard__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.resourcesSectionCard__container > div{
    height: 100%;
    width: 32%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.card_part > * {
    margin-bottom: 16px;
}

.resourcesSectionCardContainer__title {
    font-size: 24px;
    font-weight: 700;
}

.resourcesSectionCardContainer__image {
    width: 100%;
    aspect-ratio: 1/1;
}

.resourcesSectionCardContainer__imageReplace {
    background-color: white;
    border: 3px solid black;
    width: 100%;
    aspect-ratio: 1/1;
}

.resourcesSectionCardContainer__caption{
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}

.gray_card{
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: $gray;
    margin-bottom: 0;
}
