.homeSection {
  min-height: 60vh;
}

/*Tablette Section*/

.homeSection__tablette {
  background: black;
  color: white;
  padding: 30px 0;
}

.homeSectionTablette__image {
  display: none;
  position: relative;
  width: 80%;
  margin: 0 auto;
}

.homeSectionTablette__image img {
  max-width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  margin-bottom: 2em;
}

.homeSectionTabletteImage__wrapper {
  display: flex;
  justify-content: center;
}

.homeSectionTablette__title {
  position: absolute;
  top: 28%;
  left: 74%;
  transform: translate(-50%, -50%);
  hyphens: none;
  text-align: left;
  color: #fff;
  width: 512px;
}

.homeSectionTablette__title h2 {
  font-size: 2rem;
}

.homeSectionTablette__title p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.homeSectionTablette__imageHigh {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeSectionTablette__imageHigh img {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .homeSectionTablette__title {
    position: static;
    text-align: center;
    transform: translate(0);
    color: #fff;
    width: 512px;
  }

  .homeSectionTablette__image img {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    margin-right: 45%;
  }
  .homeSectionTablette__imageHigh img {
    width: 90%;
  }
}

@media screen and (max-width: 800px){
  .homeSectionTablette__imageHigh{
    display: none;
  }

  .homeSectionTablette__image{
    display: block;
  }
}
/*Presentation Section*/

.homeSection__presentation {
  margin-top: 80px;
  margin-bottom: 100px;
}

.homeSectionPresentation__content {
  display: flex;
}

.homeSectionPresentationContent__partLeft {
  width: 50%;
}

.homeSectionPresentationContentPartLeftTitle__image img {
  display: flex;
  width: 10vw;
}

.homeSectionPresentationContentPartLeftTitle__image h2 {
  position: absolute;
  margin-left: 7vw;
  width: 35%;

  font-size: 2vw;
}

.homeSectionPresentationContentPartLeft__informations {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-left: 0;
}

.homeSectionPresentationContentPartLeftInformations__column {
  display: flex;
  margin: 0 25px;
}

.homeSectionPresentationContentPartLeftInformationsColumn__card {
  width: 300px;
  margin-bottom: 25px;
}

.homeSectionPresentationContentPartLeftInformationsColumnCard__image img {
  height: 50px;
}

.homeSectionPresentationContentPartLeftInformationsColumnCardText__title h3 {
  margin-top: 5px;
}

.homeSectionPresentationContentPartLeftInformationsColumnCardText__details p {
  line-height: 20px;
  font-size: 12px;
  margin-right: 25px;
}

.homeSectionPresentationContentPartLeftInformationsColumnCardText__details
  p
  br {
  content: "";
  display: block;
}

.homeSectionPresentationContent__laptopImg {
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
  overflow: hidden;
}

.homeSectionPresentation__button {
  margin: 4em auto;
  width: fit-content;
  padding: 20px 15px;
  border-radius: 35px;
  background: linear-gradient(
                  87.93deg,
                  rgba(0, 0, 0, 0.2) -4.92%,
                  rgba(67, 67, 67, 1) -4.91%,
                  rgba(0, 0, 0, 1) 96.51%
  );
  backdrop-filter: blur(15px);
  position: relative;
}

.homeSectionPresentation__button a {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .homeSectionPresentation__content {
    display: flex;
    flex-direction: column;
  }
  .homeSectionPresentationContent__partLeft {
    width: 100%;
  }

  .homeSectionPresentationContentPartLeftTitle__image img {
    display: flex;
    width: 25%;
  }
  .homeSectionPresentationContentPartLeftTitle__image h2 {
    position: absolute;
    margin-left: 17vw;
    width: 80%;
    font-size: 5vw;
  }
  .homeSectionPresentationContentPartLeft__informations {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCardText__details p {
    line-height: 20px;
    font-size: 12px;
    margin: 0 15px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCard__image {
    display: flex;
    justify-content: center;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCardText__title h3 {
    text-align: center;
    margin-bottom: 15px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCardText__details p {
    line-height: 20px;
    font-size: 12px;
    text-align: center;
  }

  .homeSectionPresentationContentPartLeftInformationsColumnCardText__details
    p
    br {
    content: "";
    display: block;
  }
  .homeSectionPresentationContent__laptopImg {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .homeSectionPresentationContent__laptopImg img {
    width: 75%;
  }
}

@media screen and (min-width: 800px) {
  .homeSectionPresentationContentPartLeftTitle__image img {
    display: flex;
    width: 200px;
  }
  .homeSectionPresentationContentPartLeftTitle__image h2 {
    position: absolute;
    margin-left: 136px;
    width: 640px;
    font-size: 40px;
  }
}

@media screen and (max-width: 800px) {
  .homeSectionPresentationContentPartLeftTitleImage__wrapper {
    width: max-content;
    top: 5%;
    left: 22%;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div {
    position: relative;
    font-size: 40px;
    width: fit-content;
    font-weight: 700;
    line-height: 1;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div span {
    position: relative;
    margin-left: 35px;
    font-size: 40px;
    width: fit-content;
    font-weight: 700;
    line-height: 70px;
  }
  .homeSectionPresentationContentPartLeft__informations {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 755px) {
  .homeSectionPresentationContentPartLeftInformations__column {
    display: flex;
    flex-direction: column;
  }
  .homeSection__presentation {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumn__card {
    width: 350px;
    margin-bottom: 50px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCard__image {
    display: flex;
    justify-content: center;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCardText__title h3 {
    text-align: center;
    margin-bottom: 15px;
  }
  .homeSectionPresentationContentPartLeftInformationsColumnCardText__details p {
    line-height: 20px;
    font-size: 12px;
    text-align: center;
  }

  .homeSectionPresentationContentPartLeftInformationsColumnCardText__details
    p
    br {
    content: "";
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .homeSectionPresentationContentPartLeftTitleImage__wrapper {
    width: max-content;
    top: 5%;
    left: 15%;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div {
    position: relative;
    font-size: 28px;
    width: fit-content;
    font-weight: 700;
    line-height: 1;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div span {
    position: relative;
    margin-left: 25px;
    font-size: 28px;
    width: fit-content;
    font-weight: 700;
    line-height: 45px;
  }
}

@media screen and (max-width: 500px) {
  .homeSectionPresentationContentPartLeftTitleImage__wrapper {
    width: max-content;
    top: 7%;
    left: 15%;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div {
    position: relative;
    font-size: 24px;
    width: fit-content;
    font-weight: 700;
    line-height: 1;
  }
  .homeSectionPresentationContentPartLeftTitleImageWrapper__text div span {
    position: relative;
    margin-left: 25px;
    font-size: 24px;
    width: fit-content;
    font-weight: 700;
    line-height: 50px;
  }
}

/*Video Player*/

.homeSection__player {
  height: 80vh;
  background: #59585a;

  font-size: 3vw;
  font-weight: 700;
  color: #f5f5f5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.homeSectionPlayer__video {
  height: 90%;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.homeSectionPlayer__button {
  position: absolute;
  background-color: #f5f5f5;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 4em;
  height: 4em;
  border-radius: 50%;

  opacity: 0.8;
}

.buttonPause {
  width: 2em;
  height: 2em;
}

.buttonPlay {
  width: 2em;
  height: 2em;
  margin-left: 15px;
  opacity: 1;
}

.homeSectionPlayer__button--hidden {
  position: absolute;
  background-color: #f5f5f5;

  width: 4em;
  height: 4em;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: hide 1500ms linear;
  opacity: 0;
}

@keyframes hide {
  0% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1300px) {
  .homeSectionPlayer__video {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .homeSection__player {
    height: 40vh;
  }
}

/*Compatibility Section*/

.homeSection__compatibility {
  margin: 150px 0;
}

/* Compatibility Section / Application */

.homeSectionCompatibility__application {
  display: flex;
  width: calc(100% - 25%);
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  text-align: left;
  line-height: 23px;
}

.homeSectionCompatibilityApplication__image {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.homeSectionCompatibilityApplication__image img {
  width: 394px;
}

.homeSectionCompatibilityApplication__text {
  width: 50%;
}

.homeSectionCompatibilityApplication__text h2 {
  font-size: 4vw;
  font-weight: 700;
  text-align: right;
  line-height: 5vw;
  width: 100%;
  height: fit-content;
}

@media screen and (max-width: 1300px) {
  .homeSectionCompatibilityApplication__text h2 {
    text-align: center;
  }
  .homeSectionCompatibilityApplication__image img {
    display: flex;
    width: 90%;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .homeSectionCompatibilityApplication__text h2 {
    text-align: center;
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 800px) {
  .homeSectionCompatibility__application {
    display: flex;
    width: calc(100% - 10%);
    justify-content: space-between;
    margin: 0px auto;
    padding-bottom: 120px;
    text-align: left;
    line-height: 23px;
  }
  .homeSectionCompatibilityApplication__image img {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 650px) {
  .homeSection__compatibility {
    margin: 50px 0;
  }
  .homeSectionCompatibilityApplication__text {
    margin-bottom: 50px;
    width: 80%;
  }
  .homeSectionCompatibility__application {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10%);
    align-items: center;
    margin: 0px auto;
    padding-bottom: 120px;
    text-align: left;
    line-height: 23px;
  }
  .homeSectionCompatibilityApplication__image {
    justify-content: center;
    width: 80%;
  }
  .homeSectionCompatibilityApplication__image img {
    width: 85%;
  }
}

/* Compatibility Section Plateform */

.homeSectionCompatibility__plateform {
  display: flex;
  width: calc(100% - 25%);
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 120px;
  text-align: left;
  line-height: 23px;
}

.homeSectionCompatibilityPlateform__image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeSectionCompatibilityPlateform__image img {
  width: 273px;
}

.homeSectionCompatibilityPlateform__text {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSectionCompatibilityPlateform__text h2 {
  font-size: 4vw;
  font-weight: 700;
  text-align: right;
  line-height: 5vw;
  width: 100%;
  height: fit-content;
}

@media screen and (max-width: 1300px) {
  .homeSectionCompatibilityPlateform__text h2 {
    text-align: center;
  }
}

@media screen and (min-width: 1000px) {
  .homeSectionCompatibilityPlateform__text h2 {
    text-align: center;
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 800px) {
  .homeSectionCompatibility__plateform {
    display: flex;
    width: calc(100% - 10%);
    justify-content: space-between;
    margin: 0px auto;
    padding-bottom: 120px;
    text-align: left;
    line-height: 23px;
  }
}

@media screen and (max-width: 650px) {
  .homeSectionCompatibility__plateform {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 120px;
    text-align: left;
    line-height: 23px;
  }

  .homeSectionCompatibilityPlateform__image {
    order: 2;
  }
  .homeSectionCompatibilityPlateform__image {
    justify-content: center;
    width: 80%;
  }
  .homeSectionCompatibilityPlateform__image img {
    width: 80%;
  }
  .homeSectionCompatibilityPlateform__text {
    margin-bottom: 50px;
    width: 80%;
  }
}

/*Solution Section*/

.homeSection__solution {
  background-image: url("../../../assets/main/home/backgroundSolution.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homeSectionSolution__text {
  color: white;
  text-align: center;
}

.homeSectionSolution__text h2 {
  padding-top: 45px;
  font-size: 6vw;
  font-weight: 700;
  text-align: center;
  line-height: 5vw;
  width: 100%;
  height: fit-content;
}

.homeSectionSolution__text p {
  padding-top: 25px;
  padding-bottom: 75px;
  font-size: 24px;
  font-weight: 700;
}

.homeSectionSolution__differentSolution {
  display: flex;
  width: calc(100% - 16%);
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 100px;
}

.homeSectionSolutionDifferentSolution__scena {
  bottom: 0;
  padding: 20px;
  width: 30%;
  border-radius: 60px;
  background: linear-gradient(
    87.93deg,
    rgba(0, 0, 0, 0.2) -4.92%,
    rgba(67, 67, 67, 1) -4.91%,
    rgba(0, 0, 0, 1) 96.51%
  );
  backdrop-filter: blur(15px);
  position: relative;
  max-height: 650px;
}

.homeSectionSolutionDifferentSolution__scena:before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    87.45deg,
    #464652 15.64%,
    #868095 32.85%,
    #282738 60.88%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.homeSectionSolutionDifferentSolutionScena__logo {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;
}

.homeSectionSolutionDifferentSolutionScena__text {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: auto;
  margin: 0px 30px;
}

.homeSectionSolutionDifferentSolutionScena__text h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.homeSectionSolutionDifferentSolutionScenaText-blue span {
  color: #236eff;
  font-size: 28px;
  font-weight: 700;
}

.homeSectionSolutionDifferentSolutionScenaText-red span {
  color: #db1a00;
  font-size: 28px;
  font-weight: 700;
}

.homeSectionSolutionDifferentSolutionScenaText-yellow span {
  color: #eb8d00;
  font-size: 28px;
  font-weight: 700;
}

.homeSectionSolutionDifferentSolutionScena__text h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}

.homeSectionSolutionDifferentSolutionScena__text p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.homeSectionSolutionDifferentSolutionScena__text p:last-child {
  margin-bottom: 50px;
}

.homeSectionSolutionDifferentSolutionScena__button {
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  background: black;
  margin: 0px auto;
  width: 150px;
  height: 48px;
  line-height: 50px;
  border-radius: 35px;
}

.homeSectionSolutionDifferentSolutionScenaButton__link {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.homeSectionSolution__buttons{
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeSection__button {
  bottom: 50px;
  margin: 0 auto;
  width: fit-content;
  padding: 20px 15px;
  border-radius: 35px;
  background: linear-gradient(
    87.93deg,
    rgba(0, 0, 0, 0.2) -4.92%,
    rgba(67, 67, 67, 1) -4.91%,
    rgba(0, 0, 0, 1) 96.51%
  );
  backdrop-filter: blur(15px);
  position: relative;
}

.homeSection__button::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(87.45deg, #464652 15.64%, #282738 60.88%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.homeSection__button a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .homeSectionSolution__differentSolution {
    flex-direction: column;
    align-items: center;
  }
  .homeSectionSolutionDifferentSolution__scena {
    margin-bottom: 50px;
    width: 50%;
  }
  .homeSectionSolutionDifferentSolution__scena:last-child {
    margin-bottom: 0;
  }
  .homeSectionSolution__buttons{
    flex-direction: column;
    width: 100%;
  }
  .homeSection__button {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .homeSectionSolution__text h2 {
    text-align: center;
    font-size: 60px;
    line-height: 50px;
  }
}

@media (min-width: 700px) and (max-width: 1300px) {
  .homeSectionSolutionDifferentSolution__scena {
    width: 588px;
    height: 524px;
  }
}

@media screen and (max-width: 700px) {
  .homeSectionSolutionDifferentSolution__scena {
    width: fit-content;
  }
  .homeSectionButtonTry__link {
    font-size: 15px;
  }
  .homeSectionSolution__text h2 {
    text-align: center;
    font-size: 42px;
    line-height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .homeSectionSolution__text p {
    font-size: 1.17em;
  }
  .homeSection__buttonTry {
    margin-top: 50px;
  }
  .homeSectionButtonTry__link {
    font-size: 16px;
  }
  .homeSectionSolutionDifferentSolutionScena__text h4 {
    font-size: 1.17em;
  }
  .homeSectionSolutionDifferentSolutionScena__text p {
    font-size: 12px;
  }
  .homeSectionSolutionDifferentSolutionScena__text h3 {
    font-size: 24px;
  }
  .homeSectionSolutionDifferentSolutionScenaText-blue span {
    font-size: 24px;
  }
  .homeSectionSolutionDifferentSolutionScenaText-red span {
    font-size: 24px;
  }
  .homeSectionSolutionDifferentSolutionScenaText-yellow span {
    font-size: 24px;
  }
  .homeSectionSolution__differentSolution {
    padding-bottom: 50px;
  }
}

/*Community Section*/

.homeSectionPreviewCommunity__text h2 {
  margin: 200px 0 100px 200px;
  font-size: 40px;
  font-weight: 700;
}

.homeSectionPreviewCommunity__images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 200px);
  margin: 0 auto;
}

.homeSectionPreviewCommunityImages__case {
  bottom: 0;
  padding-bottom: 50px;
  width: 150px;
  height: 150px;
  border-radius: 60px;
  background: white;
  border: 2px solid black;
  backdrop-filter: blur(15px);
  position: relative;
}

/*Carousel Section*/

.homeSection__carousel {
  margin-top: 128px;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeSectionCarousel__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
}
