.aboutSection {
  min-height: 60vh;
  height: auto;
}

.aboutSection__image {
  width: 100%;
  height: 90vh;
  object-fit: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutSection__wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 90vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutSection__wrapper > * {
  color: #fff;
  text-align: center;
}

.aboutSectionWrapper__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.aboutSectionWrapper__caption {
  font-weight: 300;
  line-height: 150%;
  width: 60%;
}

.teamSection__elevate {
  margin-left: 100px;
}

.teamSectionElevate__title {
  font-size: 28px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 20px;
}

.teamSectionElevate__subTitle {
  font-size: 24px;
  font-weight: 700;
  color: #f37643;
}

.teamSectionElevate__text {
  font-size: 16px;
  font-weight: 400;
  width: 34%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.teamSectionElevateLink__button,
.teamSectionElevate__link img {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 50px;
  color: #f37643;
  cursor: pointer;
}

.teamSectionElevate__link {
  text-align: center;
  text-decoration: none;
}
