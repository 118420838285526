.identifySection{
    height: 60vh;
    width: 100%;
    background-color: white;
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.identifySection__title{
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
}

.identifySection__text{
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 32px;
}

.identifySection__buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.identifySection__button{
    font-size: 16px;
    font-weight: 300;
    background-color: #f37643;
    color: white;
    padding: 16px;
    border-radius: 4px;
    margin-right: 16px;
}

.identifySection__button:hover{
    cursor: pointer;
}