*,
html {
  box-sizing: border-box;
}

.scenaComSectionShowcase__background img {
  display: flex;
  width: 100%;
  justify-content: center;
}

.scenaComSectionShowcaseBackground__wrapper {
  position: absolute;
  top: 590px;
  left: 7%;
}

.scenaComSectionShowcaseBackgroundWrapper__title {
  display: flex;
  flex-direction: column;
}

.scenaComSectionShowcaseBackgroundWrapper__title h2 {
  position: relative;
  color: white;
  font-size: 64px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 10px;
}

.scenaComSectionShowcaseBackgroundWrapper__title h2 span {
  position: relative;
  color: #eb8d00;
  font-size: 64px;
  font-weight: 700;
  line-height: 23px;
}

.scenaComSectionShowcaseBackgroundWrapper__title p {
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-top: 25px;
}

.presentationSectionText__management {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
}

.presentationSectionText__management p {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  width: 100%;
  text-align: justify;
}

.presentationSectionText__management p:last-child {
  margin-bottom: 50px;
}

.container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.presentationSectionText__solution {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 45%;
  margin-right: 200px;
}

.presentationSectionText__solution p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 150px;
  line-height: 28px;
  text-align: justify;
}

.solutionSection {
  background: #000;
  color: white;
}

.solutionSection__text {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  padding-top: 100px;
}

.solutionSection__demo img {
  display: flex;
  margin: auto;
}

.solutionSection__button {
  padding: 50px;
}

.solutionSection__buttonCom button {
  display: flex;
  text-decoration: none;
  background: #eb8d00;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  padding: 15px 20px;
  border: none;
  border-radius: 51px;
  cursor: pointer;
}
